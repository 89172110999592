import { ChangeEvent, useEffect, useState } from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { useStripe } from '@stripe/react-stripe-js';
import MenuTitle from 'Components/Parts/MenuTitle';
import styled from 'styled-components';
import loading from 'Assets/loading.svg';
import { UserPayment } from 'Schemes/Payment';
import { Chip } from 'Schemes/Chip';
import Config from 'Config/Config';
import PageNotFound from 'Components/PageNotFound';
import FormLabel from 'Components/Parts/Forms/Label';
import FiveStarRateTouchable from 'Components/Parts/FiveStarRateTouchable';
import { PrimaryButton, CancelButton } from 'Components/Parts/Buttons';
import { TextArea } from 'Components/Parts/Forms/TextArea';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';
import { formatTimeString, minutesToStr } from 'Utils/TimeUtil';
import { NotificationSuccess, NotificationAlert } from 'Components/Parts/Notification';

const LoadingImg = styled.img`
  display: block;
  margin: 100px auto;
  width: 50px;
  height: 50px;
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
`;

const Caption = styled.div`
  font-size: 12px;
  color: ${Colors.gray};
`;

const Example = styled.div`
  background-color: ${Colors.bgPink};
  border: 1px solid ${Colors.borderGray};
  border-radius: 5px;
  white-space: pre-wrap;
  padding: 8px;
  margin-top: 16px;
`;

const TicketArea = styled.div`
  display: flex;
  min-height: 130px;
  width: 100%;
  background-color: ${Colors.white};
`;

const TextField = styled.input`
  width: 90%;
  margin-right: auto;
  font-size: 18px;
  height: 40px;
  line-height: 60px;
  display: block;
  text-align: center;
  border: 1px solid ${Colors.borderGray};
  border-radius: 5px;
  text-align: right;
`;

const ChipArea = styled.div`
  width: 100%;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const IconArea = styled.div`
  position: relative;
  width: 130px;
`;

const PriceArea = styled.div``;

const PlayerIcon = styled.img`
  position: absolute;
  z-index: 0;
  top: 20px;
  left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
`;

const Time = styled.p`
  margin: 20px 0 0;
  font-size: 12px;
  color: ${Colors.gray};
`;

const PlayerName = styled.p`
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
`;

const Price = styled.span`
  color: ${Colors.pink};
  font-weight: bold;
  padding-right: 20px;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-top: 30px;
`;

const StyledFiveStarRateTouchable = styled(FiveStarRateTouchable)`
  margin-top: 20px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 80%;
  margin: 20px auto;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
`;

const StyledCancelButton = styled(CancelButton)`
  width: 80%;
  margin: 20px auto;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
`;

const ChipList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ChipBLock = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid ${Colors.borderGray};
`;

const ChipPrice = styled.span`
  color: ${Colors.pink};
  font-weight: bold;
`;

const ChipTime = styled.p`
  font-size: 12px;
  color: ${Colors.gray};
`;

const PresentCampaign = styled.p`
  color: ${Colors.pink};
  font-size: 16px;
  font-weight: bold;
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ModalChipCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
`;

const ModalChipPrice = styled.span`
  color: ${Colors.pink};
  font-weight: bold;
  font-size: 32px;
`;

const NoChipHistory = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
`;

Modal.setAppElement('#root');

type CreateReviewParams = {
  PaymentUUID: string;
  Rating: number;
  Comment: string;
  Chip?: number;
};

const PlayerReviewForm = ({ paymentUUID }: { paymentUUID: string }) => {
  const stripe = useStripe();
  const [paymentMethods, setPaymentMethods] = useState("");
  const [payment, setPayment] = useState<UserPayment | null>(null);
  const [chipErrorMessage, setChipErrorMessage] = useState<string | null>(null);
  const [notFound, setNotFound] = useState(false);
  const [chips, setChips] = useState<Array<Chip>>([]);
  const [reviewParams, setReviewParams] = useState<CreateReviewParams>({
    PaymentUUID: paymentUUID,
    Rating: 5.0,
    Comment: '',
    Chip: 0,
  });
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    const fetchPayment = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/payments/${paymentUUID}`)
        .then((res) => {
          setPayment(res.data);
          setReviewParams((prev) => {
            return {
              ...prev,
              Rating: res.data.Rating || 5.0,
              Comment: res.data.Comment,
            };
          });
        })
        .catch((err) => {
          if (err.response.status === 404) setNotFound(true);
        });
    };

    // このチケット購入時の決済情報を取得
    const fetchPaymentMethod = async () => {
      await Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/checkout/payment_method?paymentUUID=${paymentUUID}`)
        .then((res)=>{
          setPaymentMethods(res.data);
        })
    };

    // チップ送信履歴を取得（チップは更新の度、入力した分だけ送られる）
    const fetchChips = async () => {
      await Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/chips?paymentUUID=${paymentUUID}`)
        .then((res)=>{
          setChips(res.data);
        })
    }

    fetchPaymentMethod();
    fetchPayment();
    fetchChips();
  }, [paymentUUID]);

  const validParams = () => {
    if (reviewParams.Comment.length < 1) {
      setAlertMessage('コメントは必須です。');
      return false;
    }

    if (reviewParams.Chip && (reviewParams.Chip < 100 || reviewParams.Chip > 10000)) {
      setAlertMessage('チップは100円以上10000円以下で設定してください。');
      return false;
    }

    if (reviewParams.Chip && reviewParams.Chip % 100 !== 0) {
      setAlertMessage('チップは100円単位で設定してください。');
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (reviewParams.Chip && reviewParams.Chip > 0) {
      setIsConfirmModalOpen(true);
    } else {
      updateReview();
    }
  }

  const updateReview = async () => {
    setIsUpdating(true);
    setSuccessMessage(null);
    setAlertMessage(null);

    if (!validParams()) {
      window.scrollTo(0, 0);
      setIsUpdating(false);
      setIsConfirmModalOpen(false);
      return;
    }

    try {
      // レビューの送信（チップがあれば一緒に送る）
      const result = await Axios.create({ withCredentials: true }).post(`${Config.API_HOST}/user/player_review`, reviewParams)
      const secret = result.data.ClientSecret;

      if (stripe && secret) {
        const confirm = await stripe.confirmCardPayment(secret, { payment_method: paymentMethods });
        if (confirm?.error) {
          // チップ送信失敗
          await Axios.create({ withCredentials: true }).post(`${Config.API_HOST}/user/chips/${result.data.ChipID}/cancel`)
          setSuccessMessage('レビューを投稿しました。');
          setChipErrorMessage('チップの送信に失敗しました');
        } else {
          // チップ送信成功
          setReviewParams((prev)=>({ ...prev, Chip: Number(0) }));
          setSuccessMessage(`レビューとチップ（${reviewParams.Chip}円）を送信しました。`);
        }
      } else {
        setSuccessMessage('レビューを投稿しました。');
      }
    } catch(err) {
      setSuccessMessage(null);
      setAlertMessage(`レビューの投稿に失敗しました。`);
    } finally {
      window.scrollTo(0, 0);
      setIsConfirmModalOpen(false);
      setIsUpdating(false);
    }
  };

  const updateRating = (i: number) => {
    setReviewParams((prev) => {
      return {
        ...prev,
        Rating: i,
      };
    });
  };

  const updateChip = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setReviewParams((prev) => {
      return {
        ...prev,
        Chip: Number(value),
      };
    });
  }

  const updateComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setReviewParams((prev) => {
      return {
        ...prev,
        Comment: value,
      };
    });
  };

  if (notFound) return  <PageNotFound />

  if (payment === null) return <LoadingImg src={loading} alt="loading" />

  return (
    <>
      <MenuTitle>一緒にプレイしたプレイヤー</MenuTitle>
      <TicketArea>
        <IconArea>
          <PlayerIcon src={payment.PlayerProfileImageURL} />
        </IconArea>
        <PriceArea>
          <Time>{formatTimeString(payment.LastUpdatedAt)}</Time>
          <PlayerName>{payment.PlayerName}</PlayerName>
          <Price>
            {numberToPrice(payment.Sales)} / {minutesToStr(payment.PlayMinutes)}
          </Price>
        </PriceArea>
      </TicketArea>
      <MenuTitle>評価する</MenuTitle>
      <Wrapper>
        {successMessage && <NotificationSuccess>{successMessage}</NotificationSuccess>}
        {alertMessage && <NotificationAlert>{alertMessage}</NotificationAlert>}
        {chipErrorMessage && <NotificationAlert>{chipErrorMessage}</NotificationAlert>}
        <Caption>
          一緒にしたプレイを評価してください。
          <br />※ 内容は全体に公開されます。
        </Caption>
        <PresentCampaign>
          今ならプレイヤー評価するだけで毎月3名様に1,000円分のAmazonギフト券プレゼント。<br/>
          （※当選者には2PLAY内メッセージで公式アカウントから送付します。）
        </PresentCampaign>
        <StyledFormLabel required={true}>楽しめましたか？</StyledFormLabel>
        <StyledFiveStarRateTouchable score={reviewParams.Rating} updateRating={updateRating} />
        <StyledFormLabel required={true}>コメント</StyledFormLabel>
        <TextArea rows={5} value={reviewParams.Comment} onChange={updateComment}></TextArea>
        <Example>例：ゲームのお話がたくさん出来て楽しかったです！またよろしくお願いします！</Example>
        <StyledFormLabel required={false}>チップを贈る</StyledFormLabel>
        <ChipArea>
          <TextField 
            type="number" 
            step={100} 
            min={0} 
            max={10000} 
            value={reviewParams.Chip} 
            onChange={updateChip} 
          />
          <span>円</span>
        </ChipArea>
        <Caption>
          ※チケット購入時と同じクレジットカードで決済されます。<br/>
          ※チップ購入決定後はキャンセルができません。
        </Caption>
        <StyledFormLabel required={false}>チップ送信履歴</StyledFormLabel>
        {chips.length === 0 ? (
          <NoChipHistory>まだチップを送っていません</NoChipHistory>
        ) : chips.length > 0 && (
          <ChipList>
            {chips.map((chip: Chip)=>{
              return (
                <ChipBLock key={chip.ID}>
                  <ChipPrice>{chip.Price}円</ChipPrice>
                  <ChipTime>{formatTimeString(chip.CreatedAt)}</ChipTime>
                </ChipBLock>
              )
            })}
          </ChipList>
        )}
      </Wrapper>
      <Wrapper>
        <StyledPrimaryButton onClick={onSubmit}>決定</StyledPrimaryButton>
      </Wrapper>

      <Modal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        contentLabel="Confirm Modal"
        style={{
          content: {
            margin: '0 auto',
            height: '260px',
            width: '90%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
          }
        }}
      >
        <MenuTitle>レビューと一緒にチップを送信します。よろしいですか？</MenuTitle>
        <br/>
        <ModalChipCard>
          <ModalChipPrice>{reviewParams.Chip}円</ModalChipPrice>
        </ModalChipCard>
        <ModalButtons>
          <StyledPrimaryButton onClick={updateReview} loading={isUpdating}>決定</StyledPrimaryButton>
          <StyledCancelButton onClick={() => setIsConfirmModalOpen(false)} loading={isUpdating}>キャンセル</StyledCancelButton>
        </ModalButtons>
        <Caption>
          ※チケット購入時と同じクレジットカードで決済されます。<br/>
          ※チップ購入決定後はキャンセルができません。
        </Caption>
      </Modal>
    </>
  ) 
}

export default PlayerReviewForm;
